<template>
  <div class="input-radio">
    <button>
      <input :id="getHtmlId(names[0])" type="radio" :name="group" value="true" @click="select(true)" :checked="componentValue">
      <label :for="getHtmlId(names[0])">{{ names[0] }}</label>
    </button>
    <button>
      <input :id="getHtmlId(names[1])" type="radio" :name="group" value="false" @click="select(false)" :checked="!componentValue">
      <label :for="getHtmlId(names[1])">{{ names[1] }}</label>
    </button>
  </div>
</template>

<script>


export default {
  props: {
    names: { type: Array, default: () => ([]) },
    group: { type: String, default: '' },
    value: { type: Boolean, default: true },
  },
  data() {
    return {
      componentValue: this.value,
    }
  },
  methods: {
    getHtmlId(value) {
      return value.toLowerCase().replaceAll(' ', '-')
    },

    select(value) {
      this.$emit('input', value)
    }
  },
};
</script>

<style>

</style>
