<template>
  <main class="main">
    <div class="container">
      <div class="block-create-token">
        <h1 class="color_green title">Token creation</h1>
        <p class="color_gray title-second text">Customize your token</p>
        <div class="wrapper">
          <tabs>
            <template v-slot:labels>
              <button class="tabs-label active">
                <div class="text">Step 1</div>
                <div class="icon arrow-circle"></div>
              </button>
              <button class="tabs-label">
                <div class="text">Step 2</div>
                <div class="icon arrow-circle"></div>
              </button>
              <button class="tabs-label">
                <div class="text">Overview</div>
                <div class="icon arrow-circle"></div>
              </button>
            </template>
            <template v-slot:items>
              <div class="tabs-item">
                <div class="form">
                  <div class="input-wrapper">
                    <input-text
                      placeholder="Enter token name"
                      :class="{invalid: (!$v.name.required && $v.name.$dirty) || (!$v.name.maxLength && $v.name.$dirty)}"
                      v-model="name"
                    />
                  </div>
                  <div class="input-wrapper">
                    <input-text
                      placeholder="Enter token symbol"
                      :class="{invalid: (!$v.symbol.required && $v.symbol.$dirty) || (!$v.symbol.maxLength && $v.symbol.$dirty)}"
                      v-model="symbol"
                    />
                  </div>
                  <div class="input-wrapper">
                    <input-variable
                      placeholder="Enter supply"
                      alternativeValue="Infinite"
                      :class="{invalid: (!$v.maxSupply.required && $v.maxSupply.$dirty) || (!$v.maxSupply.isBytes && $v.maxSupply.$dirty)}"
                      v-model="maxSupply"
                    />
                    <div class="small input-notice">This is the total supply of your token <br> (it can be adjusted later)</div>
                  </div>
                  <div class="input-wrapper">
                    <input-text
                      placeholder="Enter decimals"
                      :class="{invalid: (!$v.decimals.required && $v.decimals.$dirty) || (!$v.decimals.isDecimals && $v.decimals.$dirty)}"
                      v-model="decimals"
                    />
                    <div class="small input-notice">Decimals determine the smallest unit of your token</div>
                  </div>

                  <button class="btn btn_outline-1" @click="step1">
                    <div class="btn__text">Next step</div>
                  </button>
                </div>
              </div>

              <div class="tabs-item">
                <div class="form">
                  <div class="input-wrapper">
                    <input-variable
                      placeholder="Enter owner"
                      alternativeValue="Renounced"
                      :class="{invalid: (!$v.owner.required && $v.owner.$dirty) || (!$v.owner.isAddress && $v.owner.$dirty)}"
                      v-model="owner"
                    />
                  </div>
                  <div class="input-wrapper">
                    <input-text
                      placeholder="Enter mint address"
                      :class="{invalid: (!$v.recipient.required && $v.recipient.$dirty) || (!$v.recipient.isAddress && $v.recipient.$dirty)}"
                      v-model="recipient"
                    />
                  </div>
                  <div class="input-wrapper">
                    <input-text
                      placeholder="Enter amount of tokens"
                      :class="{invalid: (!$v.amount.required && $v.amount.$dirty) || (!$v.amount.isBytes && $v.amount.$dirty)}"
                      v-model="amount"
                    />
                  </div>
                  <div class="input-wrapper">
                    <input-radio
                      :names="['Mintable', 'Not mintable']"
                      group="mintable"
                      v-model="mintable"
                    />
                  </div>
                  <div class="input-wrapper">
                    <input-radio
                      :names="['Burnable', 'Not burnable']"
                      group="burnable"
                      v-model="burnable"
                    />
                  </div>

                  <button class="btn btn_outline-1" @click="step2">
                    <div class="btn__text">Next step</div>
                  </button>
                </div>
              </div>

              <div class="tabs-item">
                <div class="form">
                  <div class="info-list">
                    <div class="item">
                      <div class="name">Token name</div>
                      <div class="value">{{ name }}</div>
                    </div>
                    <div class="item">
                      <div class="name">Owner’s address</div>
                      <div class="value">{{ owner | addressFormat }}</div>
                    </div>
                    <div class="item">
                      <div class="name">Token symbol</div>
                      <div class="value">{{ symbol }}</div>
                    </div>
                    <div class="item">
                      <div class="name">Mint address</div>
                      <div class="value">{{ recipient | addressFormat }}</div>
                    </div>
                    <div class="item">
                      <div class="name">Amount of tokens</div>
                      <div class="value">{{ amount }}</div>
                    </div>
                    <div class="item">
                      <div class="name">Mintable</div>
                      <div class="value">{{ mintable ? 'Yes' : 'No' }}</div>
                    </div>
                    <div class="item">
                      <div class="name">Total supply</div>
                      <div class="value">{{ maxSupply }}</div>
                    </div>
                    <div class="item">
                      <div class="name">Burnable</div>
                      <div class="value">{{ burnable ? 'Yes' : 'No' }}</div>
                    </div>
                    <div class="item">
                      <div class="name">Decimals</div>
                      <div class="value">{{ decimals }}</div>
                    </div>
                  </div>
                  <button class="btn btn_outline-1" @click="deploy">
                    <div class="btn__text">Deploy</div>
                  </button>
                </div>
              </div>
            </template>
          </tabs>

        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapActions } from 'vuex'
import { required, maxLength } from 'vuelidate/lib/validators';

import Tabs from "../components/tabs.vue"
import InputText from '@/components/inputs/text.vue';
import InputVariable from '@/components/inputs/variable.vue';
import InputRadio from '@/components/inputs/radio.vue';

function getBytes(number) {
  if (number !== 0) {
    return Math.ceil(number.toString(16).length / 2);
  }
  return 0;
}

function isAddress(value) {
  let isAddress = true

  if (value) {
    value
      .split(',')
      .map(item => item.replaceAll(' ', ''))
      .forEach(item => {
        if (!(/0x/.test(item) && item.length == 42)) {
          isAddress = false
        }
      });
  } else {
    isAddress = false
  }

  return isAddress
}

function isDecimals(value) {
  return 0 <= value && value <= 18 
}

// struct CreationData {
//   string name; - название токена (строка поставь ограничение на 200 символов)
//   string symbol; - символ токена (строка поставь ограничение на 200 символов)
//   uint256 maxSupply; - максимальный сапплай (максимально 256 байт в числе)
//   uint8 decimals; - кол-во знаков после запятой (максимальное число 18)
//   address owner; - адрес владельца, если renounce - нулевой адрес (строка 42 символа, первые 2 символа обязательно 0x) - 0x8741A94F338EC33FD077cb6a03778B792Fe3Db78 пример
//   address[] recipients; - адреса куда минтить (массив строк с правилами как у переменной owner)
//   uint256[] amounts; - кол-во сколько минтить (массив чисел по правилам как переменная maxSupply)
//   bool mintable; - доступен ли метод минт после деплоя (boolean)
//   bool burnable; - достпно ли сжигание токена (boolean
// }


export default {
  components: {
    Tabs,
    InputText,
    InputVariable,
    InputRadio
  },
  name: 'CreateToken',
  data() {
    return {
      name: '',
      symbol: '',
      maxSupply: '',
      decimals: '',
      owner: '',
      recipient: '', // []
      amount: '', // []
      mintable: true,
      burnable: true,

      step1Fields: [ 'name', 'symbol', 'maxSupply', 'decimals' ],
      step2Fields: [ 'owner', 'recipient', 'amount' ],
    }
  },
  watch: {
    maxSupply() {
      if (this.maxSupply == 'Infinite') {
        this.maxSupply = '0'
      }
    },
    owner() {
      if (this.owner == 'Renounced') {
        this.owner = '0x0000000000000000000000000000000000000000'
      }
    }
  },
  validations: {
    name: { required, maxLength: maxLength(200) },
    symbol: { required, maxLength: maxLength(200) },
    maxSupply: { required, isBytes: (value) => { return getBytes(value) < 255 } },
    decimals: { required, isDecimals },
    owner: { required, isAddress },
    recipient: { required, isAddress },
    amount: { required, isBytes: (value) => { return getBytes(value) < 255 } },
  },
  methods: {
    ...mapActions(['createToken']),

    step1() {
      let isError = false

      this.step1Fields.forEach(field => {
        if (this.$v[field].$invalid) {
          this.$v[field].$touch()
          isError = true
        }
      })

      const tabLabels = document.querySelectorAll('.tabs-label')
      if (!isError) {
        tabLabels[0].classList.add('success')
        tabLabels[1].click()
      }
    },
    step2() {
      let isError = false

      this.step2Fields.forEach(field => {
        if (this.$v[field].$invalid) {
          this.$v[field].$touch()
          isError = true
        }
      })

      const tabLabels = document.querySelectorAll('.tabs-label')
      if (!isError) {
        tabLabels[1].classList.add('success')
        tabLabels[2].click()
      }
    },
    async deploy() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }

      const creationData = {
        name: this.name,
        symbol: this.symbol,
        maxSupply: +this.maxSupply,
        decimals: +this.decimals,
        owner: this.owner,
        recipients: this.recipient.split(',').map(item => item.replaceAll(' ', '')),
        amounts: this.amount.split(/\,|\s/).filter(item => item).map(item => +(item.replaceAll(' ', ''))),
        mintable: this.mintable,
        burnable: this.burnable,
      }

      console.log('creationData: ', creationData);

      await this.createToken(creationData)
    }
  },
}
</script>
