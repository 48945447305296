<template>
  <div class="input-variable">
    <div class="input-base" ref="input-wrapper">
      <input
        ref="input"
        class="input"
        type="text"
        :placeholder="placeholder"
        v-model.trim="componentValue"
      >
    </div>
    <label class="input-checkbox">
      <input type="checkbox" ref="input-alternative" v-model="alternativeActive">
      <div class="input"></div>
      <div class="text small">{{ alternativeValue }}</div>
    </label>
  </div>
</template>

<script>

export default {
  props: {
    placeholder: { type: String, default: '' },
    value: { type: String, default: '' },
    alternativeValue: { type: String, default: '' },
  },
  data() {
    return {
      componentValue: this.value,
      alternativeActive: false
    }
  },
  watch: {
    value () {
      if (this.alternativeActive) {
        return
      }

      this.componentValue = this.value;
    },
    componentValue () {
      if (this.alternativeActive) {
        this.$emit('input', this.alternativeValue);
        return
      }

      this.$emit('input', this.componentValue);
    },

    alternativeActive() {
      const inputEl = this.$refs['input']

      if (this.alternativeActive) {
        inputEl.setAttribute('readonly', '')
        this.componentValue = this.alternativeValue
      } else {
        inputEl.removeAttribute('readonly')
        this.componentValue = ''
      }
    }
  }
};
</script>

<style>

</style>
