<template>
  <div class="input-base" ref="input-wrapper">
    <input
      type="text"
      class="input"
      :placeholder="placeholder"
      v-model.trim="componentValue"
    />
  </div>
</template>

<script>
import InputMixin from './mixin'

export default {
  mixins: [InputMixin],
};
</script>

<style>

</style>
