<template>
  <div class="tabs" ref="tabs">
    <div class="tabs-labels">
      <slot name="labels"></slot>
    </div>

    <div class="tabs-items">
      <slot name="items"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    activeClassName: { type: String, default: 'active' }
  },
  methods: {
    getElements () {
      const tabsEl = this.$refs.tabs;
      const labelsEl = tabsEl.querySelectorAll('.tabs-label');
      const itemsEl = tabsEl.querySelectorAll('.tabs-item');
      return { labelsEl, itemsEl };
    },
    addListenner () {
      const { labelsEl, itemsEl } = this.getElements();
      labelsEl.forEach((label, index) => {
        label.addEventListener('click', () => {
          // Мoдификация для этого сайта
          // if (!label.classList.contains('success')) {
          //   return
          // }

          itemsEl.forEach(item => {
            item.style.transform = `translateX(-${index * 100}%)`;
          });

          labelsEl.forEach(label => {
            label.classList.remove(this.activeClassName);
          });
          label.classList.add(this.activeClassName);
        });
      });
    }
  },
  mounted () {
    this.addListenner();
  }
};
</script>

<style>
</style>
