export default {
  props: {
    placeholder: { type: String, default: '' },
    value: { type: String, default: '' },
  },
  data () {
    return {
      componentValue: this.value
    };
  },
  watch: {
    value () {
      this.componentValue = this.value;
    },
    componentValue () {
      this.$emit('input', this.componentValue);
    }
  }
};
